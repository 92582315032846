<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="场景方产品名称">
              <el-input v-model="searchForm.productName" placeholder="请输入要搜索的产品编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="核心企业名称">
              <el-input v-model="searchForm.productName" placeholder="请输入要搜索的核心企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资金方">
              <el-input v-model="searchForm.capName" placeholder="请输入要搜索的资金方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="上架状态">
              <el-select v-model="searchForm.state" placeholder="请选择要搜索的上架状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.productState" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="场景方产品编号">
              <el-input v-model="searchForm.productCode" placeholder="请输入要搜索的场景方产品编号" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="产品类型">
              <el-select v-model="searchForm.productFinancing" placeholder="请选择要搜索的产品类型" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.productType" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="$router.push({ name: 'productSceneSideEdit', params: { editMode: 'add' } })">
            新增场景方产品
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="180">
            <template v-slot="scope">
              <ics-auth-manage code="场景方金融产品">
                <el-link v-if="[0, 3].includes(scope.row.approvalStatus)" type="primary" style="margin-left: 10px;" class="text-btn" @click="dialogAudit(scope.row.productCode)">
                  审批
                </el-link>
              </ics-auth-manage>
              <el-link v-if="['Wait', 'BeenOff'].includes(scope.row.state) && [1, 4, 5].includes(scope.row.approvalStatus)" type="primary" class="text-btn" @click="editBeen(scope.row, 'BeenOn')">
                上架
              </el-link>
              <el-link v-if="scope.row.state === 'BeenOn' && [1, 4, 5].includes(scope.row.approvalStatus)" type="danger" class="text-btn" @click="editBeen(scope.row, 'BeenOff')">
                下架
              </el-link>
              <el-link v-if="[1, 4, 5].includes(scope.row.approvalStatus) && scope.row.state !== 'Wait'" type="primary" class="text-btn" @click="changeEdit(scope.row.productCode)">
                变更
              </el-link>
              <el-link v-if="scope.row.approvalStatus === 2" type="danger" class="text-btn" @click="deleteBusiness(scope.row.productCode)">
                删除
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'productSceneSideDetail', query: { productCode: scope.row.productCode } })">
                详情
              </el-link>
              <el-link v-if="[3].includes(scope.row.approvalStatus)" type="primary" style="margin-left: 10px;" class="text-btn" @click="$router.push({ name: 'productSceneHistoryDetail', query: { productCode: scope.row.productCode } })">
                历史详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
    <ics-audit-inner :loading-submit="loadingSubmit" :dialog="dialog.audit" @submit="submitAudit" />
    <ics-dialog-inner :visible.sync="change.visible" :title="change.title" submit-title="确定" cancel-title="关闭" class="form-dialog" width="40%" @submit="confirmUpdate" @close="close">
      <el-form :model="formInfo" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
        <el-col :span="24" style="margin-top: 30px;">
          <el-form-item label="" label-width="170px">
            <el-radio-group v-model="formInfo.editType">
              <el-radio label="1">
                仅基础数据变更
              </el-radio>
              <el-radio label="2">
                变更(包含合同模板)
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
import IcsAuthManage from '@/components/auth-manage'
import IcsAuditInner from '@/components/audit-inner'
import IcsDialogInner from '@/components/dialog-inner'

export default {
  components: { IcsAuthManage, IcsAuditInner, IcsDialogInner },
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        productCode: '',
        productName: '',
        capName: '',
        state: '',
        createdStart: '',
        createdEnd: ''
      },
      formInfo: {
        productCode: '',
        editType: '1',
      },
      loadingSubmit: {
        submit: false
      },
      change: {
        visible: false,
        title: '变更选项'
      },
      businessId: '',
      dialog: {
        audit: {
          visible: false,
          title: '审批场景方产品'
        }
      },
      columnOption: [
        { label: '场景方产品编号', prop: 'productCode', formatter: this.utils.isEffective, minWidth: 170 },
        { label: '场景方产品名称', prop: 'productName', formatter: this.utils.isEffective, minWidth: 170 },
        { label: '产品类型', prop: 'productFinancing', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'productType'), minWidth: 140 },
        { label: '资金方', prop: 'capName', formatter: this.utils.isEffective, minWidth: 170 },
        { label: '核心企业名称', prop: 'coreName', formatter: this.utils.isEffective, minWidth: 170 },
        { label: '状态', prop: 'state', formatter: (row, col, cell) => this.utils.statusFormat(String(cell), 'productState'), minWidth: 100 },
        { label: '审批状态', prop: 'approvalStatus', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'productAuditStatus'), minWidth: 100 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '使用次数', prop: 'crdNumber', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '累计贷款额度(元)', prop: 'hisUseAmount', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdStart', 'createdEnd')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.product.sceneSide.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    dialogAudit (val) {
      this.businessId = val
      this.dialog.audit.visible = true
    },
    submitAudit (formData) {
      return new Promise((resolve, reject) => {
        this.loadingSubmit.submit = true
        let data = formData || {}
        console.log(formData)
        this.api.product.sceneSide.alSceneStatus(this.businessId, data.state, data.reason).then(result => {
          if (result.data.success === true) {
            this.loadingSubmit.submit = false
            this.dialog.audit.visible = false
            this.$message.success('操作成功')
            this.getList()
          } else {
            this.$message.error(result.data.message)
            this.loadingSubmit.submit = false
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loadingSubmit.submit = false
        })
      })
    },
    editBeen (row, enabled) {
      this.api.product.product.altProductState(row.productCode, enabled).then(result => {
        if (result.data.success === true) {
          this.loading.list = false
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.list = false
      })
    },
    deleteBusiness (val) {
      this.api.product.product.productDel(val).then(result => {
        if (result.data.success === true) {
          this.loading.submit = false
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    },
    changeEdit (productCode) {
      this.change.visible = true
      this.formInfo.productCode = productCode
    },
    confirmUpdate () {
      if (this.formInfo.editType === '2') {
        const data = {
          productCode: this.formInfo.productCode
        }
        this.editBeen(data, 'BeenOff')
      }
      this.$router.push({ name: 'productSceneSideEdit', params: { editMode: 'edit' }, query: { productCode: this.formInfo.productCode, editType: this.formInfo.editType } })
    },
    close () {
      this.formInfo.editType = '1'
      this.formInfo.productCode = ''
    }
  }
}
</script>

<style>

</style>
